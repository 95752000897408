.MuiInputLabel-outlined {
  transform: translate(14px, 10px) scale(1) !important;
}

.MuiOutlinedInput-input {
  padding: 8px 14px !important;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(14px, -6px) scale(0.75) !important;
}
