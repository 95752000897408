.app {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  position: fixed;
}
.topBarContainer {
  height: 64px;
}

.appContent {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;
}
.sideMenuContainer {
  width: 248px;
  height: 100%;
  flex-shrink: 0;
}
.mainContentContainer {
  width: 100%;
  height: 100%;
  background-color: #f9fafb;
}
