.dateInput {
  border: 1px solid #727cf5;
  color: #727cf5;
  border-radius: 4px;
  outline: none;
  padding-left: 40px;
  background-image: url(./today-24px.svg);
  background-repeat: no-repeat;
  width: 120px;
  height: 34px;
  background-position: 8px center;
  font-size: 14px;
}

.react-datepicker__day--selected {
  background-color: #727cf5 !important;
}
.react-datepicker__day--keyboard-selected {
  background-color: #727cf5 !important;
}